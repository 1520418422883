export const environment = {
    production: false,
    brandCode: 'yakinikuking',
    env: 'dev',
    firebase: {
        apiKey: 'AIzaSyCwknli0P-WCiKjwPqMN-VIqgRamDxl_-A',
        authDomain: 'mc-app-yakinikuking-dev.firebaseapp.com',
        projectId: 'mc-app-yakinikuking-dev',
        storageBucket: 'mc-app-yakinikuking-dev.appspot.com',
        messagingSenderId: '208587929763',
        appId: '1:208587929763:web:74ae2e2d9f51390e84a716',
        measurementId: 'G-JKW38VB0K8',
    },
    hostingBaseURL: 'https://app-dev.yakiniku-king.jp',
    appScheme: 'king-monogatari-app-dev',
    storeURL: {
        ios: 'https://apps.apple.com/app/id1345275897',
        android: 'https://play.google.com/store/apps/details?id=jp.yakiniku.king',
    },
}
